//common method related to mutations processing
export default {
    data: function () {
        return {
            lineageFolder: "./lineages",
            lineageListFile: "./lineages/list.txt",
        }
    },
    methods: {
        async loadLineageList(url) {

            if (!url || typeof url != 'string') {
                // eslint-disable-next-line no-console
                console.error(`Not a valid url: ${url}`);
                return [];
            }

            const text = await window.d3.text(url)
            let lineages = []
            let lines = text.split("\n");
            lines.forEach((l) => {
                if (!l.startsWith("#")) {
                    lineages.push(l.trim());
                }
            });

            // eslint-disable-next-line no-console
            console.log('returning ' + lineages)
            return lineages;

        },
    }
}