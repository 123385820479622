<template>
  <div class="container-fluid m-0 mb-4 pb-4">
    <div class="alert alert-info" role="alert">
      <b>ALPHA version.</b> Please note that this page is currently in alpha
      stage, contains limited data, and may have issues. If you have any
      comments or questions please send us
      <a href="mailto:Godziklab.server@medsch.ucr.edu?subject=[Viral.Pathogens3D:drug_resistance]"
        title="Click to contact us by email: Godziklab.server@medsch.ucr.edu">
        email</a>.
    </div>
    <div class="row m-12 p-3">
      <div class="col col-md-12 col-sm-12">
        <h1>Genomic diversity</h1>
        <p>
          This viewer displays information about mutations in residues within a specified distance from an inhibitor or
          interacting protein bound to the selected protein structure. Such mutations can change the binding affinity
          resulting in drug resistance of the virus. The top section contains a 3D view of the selected structure which
          can be colored according to missense mutations found in the vicinity of the ligand. The bottom section shows
          information about specific mutations, resulting changes in binding affinity calculated with PremPLI and changes
          in the frequency of these mutations over time (here, the time data are placeholders for demonstration only, it
          may be used when real pandemic tracking is ongoing). Tracking analyzes include only mutations with total counts
          of at least 5.
        </p>
        <p>For more information please see our paper <a href="https://www.biorxiv.org/content/10.1101/2022.05.27.493798v1"
            target="_blank">preprint</a> focused on SARS-CoV-2.</p>
      </div>
    </div>
    <div class="row m-12 p-3">
      <div class="col col-md-12 col-sm-12">
        <!-- <div class="col"> -->
        <form class="border p-3 mb-4 bg-light">
          <!-- user input controls -->
          <div class="form-row">
            <div class="form-group col-md-4 col-sm-12">
              <label for="selectedProtein">Protein</label>
              <select class="form-control form-control-sm" id="selectedProtein" v-model="selectedProtein">
                <!-- @change="selectedProteinChanged($event)"> -->
                <option v-for="p in proteins" :value="p" :key="p.key">
                  {{ p.label }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4 col-sm-12">
              <label for="selectedPDB">Structure to display</label>
              <select class="form-control form-control-sm" id="selectedPDB" v-model="selectedPDB">
                <option v-for="p in currentPDBList" :value="p" :key="p.id">
                  {{ p.properties.pdbid }} [{{ p.start }}:{{ p.end }}]
                </option>
              </select>
            </div>
            <!-- </div>
            <div class="form-row"> -->
            <div class="form-group col-md-4 col-sm-12">
              <label for="selectedLineage">Lineage</label>
              <select class="form-control" id="selectedLineage" name="selectedLineage" size="1" v-model="selectedLineage"
                @change="setLineage({})">
                <option selected key="all" value="All_lineages">
                  All lineages
                </option>
                <option v-for="value in lineages" :key="value" :value="value">
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row m-4" v-if="selectedPDB === null">
      Select protein and structure to display.
    </div>
    <div class="row m-4" v-if="pleaseWait">Please wait...</div>
    <div class="row ml-4">
      <threeDMolWrapper v-if="selectedPDB != null" :loading="loading" :showRandomSelectBox="false"
        :enablePositionSelection="true" :mappedPosition="mappedPosition" :clickedResidue="clickedResidue"
        :selectedPDB="selectedPDB" :mutations="mutationsInPDB" :presetPositions="mutationFromLiterature"
        :references="refMap" :mutationCounts="mutationCounts" v-on:update:v3d-posfilter="updateMutations($event)"
        v-on:update:v3d-colorscale="colorScale = $event">
      </threeDMolWrapper>
    </div>
    <div class="row my-4 pb-4" v-if="selectedPDB != null && mutations.length > 0">
      <div class="col col-md-14">
        <VariantsChart :title="'Distribution of missense mutations in ' +
          (selectedLineage === 'All_lineages'
            ? 'all lineages'
            : selectedLineage)
          " :mutations="mutations" :protein="selectedProtein.key" :structure="selectedPDB">
        </VariantsChart>
      </div>
    </div>

    <div class="row my-4 pb-4" v-if="selectedPDB != null && mutations.length > 0">
      <div class="col col-md-12">
        <VariantsTable :feature="selectedPDB" :mutations="mutations"></VariantsTable>
      </div>
    </div>
  </div>
</template>
<script>
import Mutations from "@/shared/Mutations.js";
import Lineages from "@/shared/Lineages.js";
import Shared from "@/shared/Shared.js";
export default {
  name: "mutsexplorer",
  mixins: [Mutations, Shared, Lineages],
  components: {
    threeDMolWrapper: () => import("../components/3DMolWrapper"),
    VariantsTable: () => import("../components/VariantsTable"),
    VariantsChart: () => import("../components/VariantsChart"),
  },
  data: function () {
    return {
      START: Date.now(),
      mainDataUrl: '',
      mutationsFile: "./lineages/All_lineages.tsv",
      structuresFile: "./drugresistance/structures/protein_structures.csv",
      annotatedMutationsFile: "./drugresistance/mutations/annotatedMutations.json",
      loading: true, //is data still loading?,
      pleaseWait: false,
      // pre-loaded data:
      proteins: [
        //parsed from protein_structures file
      ],

      proteinPdbs: {},
      lineages: [],

      // // data based on user selection
      // user input:
      selectedPDB: null, // selected structure

      // actual content of the pdb file
      selectedPDBData: {},

      //selected protein object
      selectedProtein: {},
      selectedLineage: "All_lineages",

      currentPDBList: [],

      // end of user input
      // all non-synonymous mutations are loaded from file
      allMutations: [
        // AA mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ],
      // mutations in current pdb, to avoid recalculation on every tick
      mutationsInPDB: [],
      // mutations at selected positions
      mutations: [
        // AA mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ],
      annotatedMutations: {},// parsed from annotatedMutationsFile

      mutationCounts: [],
      clickedResidue: -1,
      mappedPosition: -1,
      colorScale: null,
    };
  },
  mounted: function () {
    // this.saveDataUrl(this.$route.params);

    // this.$nextTick(function () {
    //   this.selectedProtein = this.proteins[0];
    //   this.currentPDBList = [];
    //   this.selectedPDB = this.currentPDBList[0];
    //   this.mutations = [];
    // });
  },
  async beforeRouteUpdate(to) {

    // eslint-disable-next-line no-console
    console.log(`GD updated: ${JSON.stringify(to.params)}`)
    await this.saveDataUrl(to.params)
  },
  async beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-console
    console.log(`GD entered: ${JSON.stringify(to.params)}`)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called
    next(async vm => {
      await vm.saveDataUrl(to.params)
    })
  },
  watch: {
    selectedProtein: function (newProt) {
      if (!newProt) return;
      // this.selectedPDB = this.currentPDBList[0];
      this.currentPDBList = this.proteinPdbs[newProt.key];

      this.selectedPDB = this.currentPDBList ? this.currentPDBList[0] : null;
    },
    selectedPDB: function () {
      this.updateMutationsInPDB();
      this.updateMutations([]);
    },
  },
  computed: {
    mutationFromLiterature() {
      return this.annotatedMutations[this.selectedProtein.key] || [];
    },
    refMap() {
      // generate unique lins of references
      const emap = new Map(); let count = 1;
      this.mutationFromLiterature.forEach((x) => {
        x.evidences.forEach((e) => {
          if (!emap.has(e)) {
            emap.set(e, count++);
          }
        })
      });
      return emap;
    }
  },
  methods: {
    // selectedProteinChanged(event) {
    //   let newProt = event.target.value;
    //   if (!newProt) return;
    //   this.selectedPDB = this.currentPDBList[0];
    //   this.currentPDBList = this.proteinPdbs[newProt.key];

    //   this.selectedPDB = this.currentPDBList[0];
    // },
    async saveDataUrl(params) {
      this.clear();
      this.mainDataUrl = this.getDataApiUrl()
        // + this.getUrlPrefix()
        + this.dataUrlFromParams(params);

      this.$nextTick(async function () {
        await this.loadData();
      })
      this.$nextTick(function () {
        this.selectedProtein = this.proteins[0];
        this.currentPDBList = [];
        this.selectedPDB = this.currentPDBList[0];
        this.mutations = [];
      });
    },
    log: function (msg) {
      const t = Date.now() - this.START;
      // eslint-disable-next-line no-console
      console.log(`MutExp@ ${t}ms: ${msg}`);
    },
    async loadData() {
      const self = this;

      self.lineages = await this.loadLineageList(this.mainDataUrl + this.lineageListFile);
      await window.d3.csv(this.mainDataUrl + this.structuresFile).then(function (structs) {
        if (structs.length === 0) {
          self.log("No structures found");
        }

        const prots = new Set();
        structs.forEach((s) => {
          let p = s["#protein"];
          let a = self.proteinPdbs[p] || [];
          let ismodel = s.is_model === "true" ? true : false;
          let x =
          {
            id: s.pdbid,
            type: "pdb",
            chain: s.chain,
            start: +s.start,
            end: +s.end,
            url: s.url,

            protstart: +s.protstart, // genomic start of the protein
            // pos: "157 162 163 164 167 247 248 264 266 267 268 273 301",
            properties: {
              pdbid: s.pdbid,
              alignment_start: +s.ali_start
            },
            isModel: ismodel
          }
          if (ismodel) {
            x.properties.Method = 'ALPHAFOLD';
            x.properties.Title = s.title;
            x.label = s.pdbid;
          }
          a.push(x);

          self.proteinPdbs[p] = a;
          prots.add(p);
        });

        for (let pr of prots)
          self.proteins.push({
            key: pr.toUpperCase(),
            label: pr,
          });
        self.selectedProtein = self.proteins[0];
        self.log(self.proteinPdbs);

        self.currentPDBList =
          self.proteinPdbs[Object.keys(self.proteinPdbs)[0]];
        self.selectedPDB = self.currentPDBList[0];
      });

      await this.loadAnnotatedMutations()
      await this.loadMutationsForCurrentLineage();
      // this.selectedProtein = this.proteins[0];
      // this.currentPDBList = [];
      // this.selectedPDB = this.currentPDBList[0];
      // this.mutations = [];
      this.loading = false;
    },
    clear() {
      this.loading = true;
      this.pleaseWait = false;
      this.proteins = [];
      this.proteinPdbs = {};
      this.lineages = [];
      this.selectedPDB = null;
      this.selectedPDBData = {};
      this.selectedProtein = {};
      this.selectedLineage = "All_lineages";
      this.currentPDBList = [];
      this.allMutations = [];
      this.mutationsInPDB = [];
      this.mutations = [
        // AA mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ];
      this.annotatedMutations = {};
      this.mutationCounts = [];
      this.clickedResidue = -1;
      this.mappedPosition = -1;
      this.colorScale = null;
    },
    async setLineage() {
      this.pleaseWait = true;
      if (!this.selectedLineage) return;
      await this.loadMutationsForCurrentLineage();
      this.pleaseWait = false;
    },
    async loadAnnotatedMutations() {
      const self = this;
      await window.d3.json(this.mainDataUrl + this.annotatedMutationsFile).then(
        function (amuts) {
          if (amuts.length === 0) {
            self.log("No annotated mutations found");
          } else {

            self.annotatedMutations = amuts;
          }
        }
      );
    },
    async loadMutationsForCurrentLineage() {
      this.log(`Loading mutations for ${this.selectedLineage}`);
      const self = this;
      const url = self.mainDataUrl + self.lineageFolder + "/" + self.selectedLineage + ".tsv";
      await this.loadMutationsData(url).then((data) => {
        // self.log(data.length);
        if (data) {
          self.allMutations = data.filter(
            (m) => m.proteinAminoAcids.indexOf(">") > 0
          );
          self.mutationCounts = new Array(data.length);
          self.mutationCounts.fill(0);
          self.allMutations.forEach(
            (m) => (self.mutationCounts[m.variant - 1] = m.aaMutationCount)
          );

          self.updateMutationsInPDB();
          // self.updateMutations([]);
        }
      });
    },
    updateMutationsInPDB() {
      if (!this.selectedPDB) {
        this.log("PDB is not selected");
        return;
      }
      this.mutationsInPDB = this.mutationsInRange(
        this.allMutations,
        this.selectedPDB.start,
        this.selectedPDB.end
      );
    },
    async updateMutations(positions) {
      if (!this.selectedPDB) {
        this.log("PDB is not selected");
        return;
      }
      this.log("Updating mutations");

      if (positions === null) {
        this.mutations = [];
      } else {
        let muts = positions.length
          ? this.mutationsAtStructurePositions(
            this.allMutations,
            this.selectedPDB,
            positions
          )
          : this.mutationsInRange(
            this.allMutations,
            this.selectedPDB.start,
            this.selectedPDB.end
          );
        this.mutations = muts;
      }
    },
    zoomTo(pos) {
      const s = this.selectedPDB.start;
      const e = this.selectedPDB.end;
      const f = this.selectedPDB.properties.alignment_start;

      this.mappedPosition = this.dna2prot(pos, s, e, f);
    },
  },
};
</script>